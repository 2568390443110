import React from 'react'

const PlayIco = (props: TODO_ANY) => {
    return (
        <svg
            width="62"
            height="62"
            fill="none"
            viewBox="0 0 62 62"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <circle cx="30.809" cy="30.809" r="30.809" fill="#225B41"></circle>
            <path
                fill="#F9FCFB"
                d="M38.105 29.463a1.555 1.555 0 010 2.693l-9.776 5.645a1.555 1.555 0 01-2.334-1.347V25.165a1.555 1.555 0 012.333-1.347l9.777 5.645z"
            ></path>
        </svg>
    )
}

export default PlayIco
