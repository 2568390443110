import styled, { css } from 'styled-components'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import MuiContainer from '@material-ui/core/Container'

import { colors, media } from '@variables'

export const Button = styled(MuiButton)``
export const Title = styled(Typography)``
export const Container = styled(MuiContainer)``

export const Wrap = styled.div`
    width: 100%;
    color: white;
    padding: 0 0 80px 0;
    background-color: ${colors.blue[500]};

    ${media.desktop} {
        padding: 0 0 200px 0;
    }

    ${Container} {
        position: relative;
    }

    ${Title} {
        padding-bottom: 40px;

        ${media.desktop} {
            padding-bottom: 80px;
        }
    }

    ${Button} {
        height: 60px;
        margin: 0 auto;
        display: block;
        background-color: #004382;
        box-shadow: 0px 15px 30px rgba(0, 67, 130, 0.4);

        ${media.phone} {
            display: none;
        }

        :hover {
            background-color: #004382;
            box-shadow: 0px 15px 30px rgba(0, 67, 130, 0.4);
        }
    }
`

export const StepperInner = styled.div`
    display: flex;
    z-index: 1;
    height: 100%;
    position: absolute;
    flex-direction: column;
    justify-content: space-between;

    left: -16px;

    ${media.desktop} {
        left: -20px;
    }
`

export const Step = styled.div<{ complete?: boolean }>`
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;

    background-color: #004d95;

    text-align: center;
    line-height: normal;
    letter-spacing: 0.02em;

    width: 38px;
    height: 38px;
    font-size: 12px;
    min-width: 38px;
    min-height: 38px;
    border: 4px solid #0060b8;

    ${media.desktop} {
        width: 50px;
        height: 50px;
        min-width: 50px;
        font-size: 15px;
        min-height: 50px;
    }

    ${({ complete }) =>
        complete &&
        css`
            background-color: white;
            color: ${colors.blue[400]};
            border: 4px solid white;
        `}
`

export const Stepper = styled.div<{ progress: number; visible: boolean }>`
    top: 0;
    z-index: 0;

    position: absolute;
    border-radius: 5px;
    background-color: #004d95;

    width: 8px;
    left: 40px;
    height: 90.8%;

    ${media.phone} {
        left: 60px;
    }

    ${media.desktop} {
        left: 0;
        right: 0;
        width: 10px;
        height: 94%;
        margin: auto;
    }

    :after {
        top: 0;
        left: 0;
        width: 100%;
        content: ' ';
        display: block;
        z-index: 0;
        position: absolute;
        border-radius: 5px;
        background-color: white;
        height: ${({ progress }) => progress}%;
    }

    ${({ visible }) =>
        !visible &&
        css`
            ${StepperInner} {
                justify-content: flex-start !important;
            }
            ${Step} {
                position: relative;
                :nth-child(1) {
                    top: -6px;
                }
                :nth-child(2) {
                    top: 26%;
                }
                :nth-child(3) {
                    top: 56%;
                }
            }
        `}
`

export const Ul = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
`

export const Li = styled.li`
    display: flex;
    position: relative;
    padding-bottom: 12px;
    align-items: flex-start;

    svg {
        top: 8px;
        width: 8px;
        height: auto;
        min-width: 8px;
        position: absolute;
    }
`

export const Span = styled.div`
    width: 100%;
    display: block;
    font-size: 14px;
    line-height: 20px;
    padding: 0 0 0 16px;
    color: ${colors.blue[200]};

    ${media.desktop} {
        font-size: 16px;
        line-height: 24px;
    }
`

export const Item = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
    padding: 0 0 40px 70px;

    ${media.phone} {
        padding: 0 0 40px 90px;
    }

    ${media.desktop} {
        padding: 0;
        max-width: 600px;
        width: calc(50% - 80px);

        :nth-child(even) {
            text-align: right;
            margin: 0 auto 40px 0;

            ${Span} {
                padding: 0 24px 0 0;
            }

            ${Li} {
                svg {
                    right: 0px;
                }
            }
        }
        :nth-child(odd) {
            text-align: left;
            margin: 0 0 20px auto;

            ${Span} {
                padding: 0 0 0 24px;
            }
        }
    }

    h5 {
        padding-bottom: 20px;

        @media (max-width: 959px) {
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 0.06em;
            text-transform: uppercase;
        }
    }
`
