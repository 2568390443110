import React, { useContext } from 'react'
import { Fade } from 'react-awesome-reveal'
import Button from '@material-ui/core/Button'
import { useTranslation } from 'react-i18next'
import Typography from '@material-ui/core/Typography'

import { Routes } from '@variables'
import Img1 from '@static/images/wallet.webp'
import Img3 from '@static/images/erc721.webp'
import Img2 from '@static/images/settings.webp'
import BgPetsImg from '@static/images/pets.webp'
import { Web3Context } from 'client/context/Web3'
import PetsImg from '@static/images/collection-pets.webp'
import { Overflow } from 'client/components/shared/styled'
import {
    Wrap,
    Img,
    Container,
    Row,
    Left,
    Right,
    Items,
    Title,
    Item,
    Text,
    ImgWrap,
    Satellite
} from './styled'

const Collection = () => {
    const [t] = useTranslation()
    const { hahdleMintButton } = useContext(Web3Context)

    return (
        <>
            <Wrap id={Routes.COLLECTION_HASH}>
                <Container>
                    <Row>
                        <Left>
                            <Overflow>
                                <Fade
                                    duration={1500}
                                    direction="up"
                                    triggerOnce
                                >
                                    <Typography variant="h6">
                                        {t('collection-undertitle')}
                                    </Typography>
                                </Fade>
                            </Overflow>
                            <Overflow>
                                <Fade
                                    delay={100}
                                    duration={1500}
                                    direction="up"
                                    triggerOnce
                                >
                                    <Title variant="h3">
                                        {t('collection-title')}
                                    </Title>
                                </Fade>
                            </Overflow>
                            <Fade triggerOnce delay={200} duration={2500}>
                                <Text>{t('collection-text')}</Text>
                            </Fade>
                            <Items>
                                <Fade triggerOnce delay={250} duration={2500}>
                                    <Item>
                                        <img
                                            src={Img1}
                                            width="48"
                                            height="48"
                                            loading="lazy"
                                            alt={t('mint-price-from')}
                                        />
                                        <div>
                                            <Typography variant="h5">
                                                <b>0,050 ETH</b>
                                            </Typography>
                                            <Typography>
                                                {t('mint-price-from')}
                                            </Typography>
                                        </div>
                                    </Item>
                                </Fade>
                                <Fade triggerOnce delay={300} duration={2500}>
                                    <Item>
                                        <img
                                            src={Img2}
                                            width="48"
                                            height="48"
                                            loading="lazy"
                                            alt={t('unique-trait')}
                                        />
                                        <div>
                                            <Typography variant="h5">
                                                <b>5</b>
                                            </Typography>
                                            <Typography>
                                                {t('unique-trait')}
                                            </Typography>
                                        </div>
                                    </Item>
                                </Fade>
                                <Fade triggerOnce delay={350} duration={2500}>
                                    <Item>
                                        <img
                                            src={Img3}
                                            width="48"
                                            height="48"
                                            loading="lazy"
                                            alt={t('token-base')}
                                        />
                                        <div>
                                            <Typography variant="h5">
                                                <b>ERC 721</b>
                                            </Typography>
                                            <Typography>
                                                {t('token-base')}
                                            </Typography>
                                        </div>
                                    </Item>
                                </Fade>
                            </Items>

                            <Fade triggerOnce delay={300} duration={2500}>
                                <Button
                                    size="medium"
                                    color="secondary"
                                    variant="contained"
                                    onClick={async () => {
                                        await hahdleMintButton()
                                    }}
                                >
                                    {t('mint-now')}
                                </Button>
                            </Fade>
                        </Left>
                        <Right>
                            <ImgWrap>
                                <Satellite>
                                    <Typography variant="h5">
                                        <b>10K</b>
                                    </Typography>
                                    <Typography>
                                        {t('unique-pieces')}
                                    </Typography>
                                </Satellite>

                                <img
                                    width="810"
                                    height="630"
                                    src={PetsImg}
                                    loading="lazy"
                                    alt="Vos NFT Pets"
                                />
                            </ImgWrap>
                        </Right>
                    </Row>
                </Container>
            </Wrap>
            <Img
                src={BgPetsImg}
                alt="Vos NFT pets"
                width="1440"
                height="691"
                loading="lazy"
            />
        </>
    )
}

export default Collection
