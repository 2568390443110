import React from 'react'
import { useTranslation } from 'react-i18next'

import Head from 'client/components/shared/Head'
import Hero from 'client/sections/Hero'
import Collection from 'client/sections/Collection'
import Metaverse from 'client/sections/Metaverse'
import App from 'client/sections/App'
import Download from 'client/sections/Download'
import Roadmap from 'client/sections/Roadmap'
import Benefits from 'client/sections/Benefits'
import About from 'client/sections/About'
import Team from 'client/sections/Team'
import Faq from 'client/sections/Faq'
import Drops from 'client/sections/Drops'

const Home = () => {
    const [t, i18n] = useTranslation()

    return (
        <>
            <Head />

            <Hero />
            <Drops />
            <Collection />
            <App />
            <Download />
            <Metaverse />
            <Roadmap />
            <Benefits />
            <About />
            <Team />
            <Faq />
        </>
    )
}

export default Home
