/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useContext } from 'react'
import { Fade } from 'react-awesome-reveal'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import { Web3Context } from 'client/context/Web3'
import { Overflow } from 'client/components/shared/styled'
import {
    Wrap,
    Container,
    Col,
    Title,
    Buttons,
    Divider,
    Tabs,
    Tab,
    TabContent,
    TabLabel
} from './styled'

const Faq = () => {
    const [t] = useTranslation()
    const { hahdleMintButton } = useContext(Web3Context)

    return (
        <Wrap>
            <Container>
                <Col>
                    <Overflow style={{ paddingBottom: 5 }}>
                        <Fade triggerOnce direction="up" duration={1500}>
                            <Title variant="h3">{t('faq-title')}</Title>
                        </Fade>
                    </Overflow>

                    <Buttons>
                        <Fade triggerOnce delay={100} duration={2500}>
                            <Button
                                size="medium"
                                color="inherit"
                                variant="contained"
                                onClick={async () => {
                                    await hahdleMintButton()
                                }}
                            >
                                {t('mint-now')}
                            </Button>
                        </Fade>
                    </Buttons>
                    <Divider />
                    <Overflow>
                        <Fade triggerOnce direction="up" duration={1500}>
                            <Typography variant="h4">
                                {t('faq-subtitle')}
                            </Typography>
                        </Fade>
                    </Overflow>
                    <Tabs>
                        {Array.from(Array(5)).map((i, idx) => (
                            <Tab key={`faq-${idx}`}>
                                <input type="checkbox" id={`faq-${idx}`} />
                                <TabLabel htmlFor={`faq-${idx}`}>
                                    <Typography>
                                        {t(`faq-item${idx + 1}-title`)}
                                    </Typography>
                                </TabLabel>
                                <TabContent>
                                    <Typography>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: t(
                                                    `faq-item${idx + 1}-text`
                                                )
                                            }}
                                        />
                                    </Typography>
                                </TabContent>
                            </Tab>
                        ))}
                    </Tabs>
                </Col>
            </Container>
        </Wrap>
    )
}

export default Faq
