import React from 'react'
import { Maybe } from '@graphql'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

type Props = {
    title?: string | Maybe<string>
    customTitle?: string
    ogUrl?: string
    ogImgUrl?: string
    keywords?: string
    description?: string | Maybe<string>
    noIndex?: boolean
}

const Head = ({
    title,
    ogUrl,
    noIndex,
    keywords,
    customTitle,
    description,
    ogImgUrl = `${process.env.RAZZLE_HOST}/og.webp`
}: Props) => {
    const [t] = useTranslation()

    const customKeywords = keywords ? keywords : t('seo-keywords')
    const customDescription = description ? description : t('seo-description')

    return (
        <Helmet>
            {!customTitle && (
                <title>
                    {t('seo-title')}
                    {title ? ` - ${title}` : ''}
                </title>
            )}
            {customTitle && <title>{customTitle}</title>}

            {noIndex && <meta name="robots" content="noindex" />}

            <meta name="keywords" content={customKeywords} />
            <meta name="description" content={customDescription} />

            <meta property="og:type" content="website" />
            <meta property="og:image" content={ogImgUrl} />
            <meta property="og:description" content={customDescription} />
            <meta
                property="og:url"
                content={`${ogUrl ? ogUrl : process.env.RAZZLE_HOST}`}
            />
            {!customTitle && (
                <meta
                    property="og:title"
                    content={`${t('seo-title')}${title ? ` - ${title}` : ''}`}
                />
            )}
            {customTitle && <meta property="og:title" content={customTitle} />}
        </Helmet>
    )
}

export default Head
