import React from 'react'
import ReactPlayer from 'react-player'
import { useTranslation } from 'react-i18next'
import PlayIco from 'client/components/SVG/Play'
import PreviewImg from '@static/images/preview.webp'
import Typography from '@material-ui/core/Typography'

import { colors } from '@variables'
import {
    Col,
    Row,
    Wrap,
    Table,
    Subtitle,
    Container,
    PlayerWrap
} from './styled'

const Drops = () => {
    const [t] = useTranslation()

    return (
        <Wrap>
            <Container>
                <div>
                    <Typography variant="h3" align="center">
                        {t('drop-title')}
                    </Typography>
                    <Subtitle variant="body1" align="center">
                        {t('drop-subtitle')}
                    </Subtitle>

                    <Row>
                        <Col>
                            <PlayerWrap>
                                <ReactPlayer
                                    loop
                                    width="100%"
                                    height="100%"
                                    controls={false}
                                    light={PreviewImg}
                                    playIcon={<PlayIco />}
                                    url="https://www.youtube.com/watch?v=i63EMBx_B8A"
                                />
                            </PlayerWrap>
                        </Col>
                        <Col>
                            <Typography variant="h4">
                                {t('drop-right-title')}
                            </Typography>
                            <Table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>{t('supply')}</th>
                                        <th>{t('mint-start')}</th>
                                        <th>{t('mint')}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr style={{ color: colors.red[200] }}>
                                        <td>{t('drop-1')}</td>
                                        <td>111</td>
                                        <td>12 / 2021</td>
                                        <td>0,035 ETH</td>
                                        <td>{t('sold-out')}</td>
                                    </tr>
                                    <tr>
                                        <td className="green-400">
                                            {t('drop-2')}
                                        </td>
                                        <td>999</td>
                                        <td>1 april / 2022</td>
                                        <td>0,050 ETH</td>
                                        <td className="green-400">
                                            {t('open')}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t('drop-3')}</td>
                                        <td>1.999</td>
                                        <td>Q2 / 2022</td>
                                        <td>0,060 ETH</td>
                                        <td>{t('coming')}</td>
                                    </tr>
                                </tbody>
                            </Table>
                            <Typography
                                variant="h6"
                                style={{ color: colors.green[200] }}
                            >
                                {t('drop-right-bottom')}
                            </Typography>
                        </Col>
                    </Row>
                </div>
            </Container>
        </Wrap>
    )
}

export default Drops
