import styled from 'styled-components'

import { colors, media } from '@variables'

export const Wrap = styled.div`
    width: 100%;
    display: flex;
    margin: 0 auto;
    align-items: center;
    flex-direction: column;

    ${media.tablet} {
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-start;
    }
`

export const Inner = styled.div`
    display: flex;
    width: 100%;
    max-width: 640px;
    justify-content: space-between;

    h6 {
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0.02em;
        color: ${colors.primary.green1};
    }
    p {
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.41px;
        text-transform: uppercase;
    }
`

export const Col = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    text-align: center;

    ${media.tablet} {
        text-align: left;
    }

    :not(:last-of-type) {
        margin-right: 30px;
    }
`
