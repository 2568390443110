import styled from 'styled-components'
import MuiContainer from '@material-ui/core/Container'

import { colors, media } from '@variables'

export const Container = styled(MuiContainer)``

export const Wrap = styled.div`
    width: 100%;
    background-color: ${colors.blue[500]};

    ${Container} {
        @media (max-width: 1169px) {
            padding: 0;
        }
    }
`

export const Inner = styled.div`
    width: 100%;
    color: white;
    padding: 40px 16px;
    background-color: #004d95;

    ${media.phone} {
        padding: 50px 40px;
    }

    ${media.desktop} {
        padding: 90px 40px;
    }
    ${media.xl} {
        border-radius: 60px;
        padding: 90px;
    }
`

export const Row = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    ${media.tablet} {
        align-items: center;
        flex-direction: row;
    }
`

export const Left = styled.div`
    width: 100%;

    ${media.tablet} {
        width: 50%;
    }

    h5 {
        padding: 4px 0 30px 0;

        ${media.tablet} {
            padding: 4px 0 22px 0;
        }
    }
`

export const Right = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    ${media.tablet} {
        width: 50%;
        padding-top: 0px;
        justify-content: flex-end;
    }
`

export const Desktop = styled.div`
    display: none;

    ${media.tablet} {
        display: flex;
    }

    a:first-of-type {
        margin-right: 14px;
    }
`

export const Mobile = styled.div`
    display: flex;
    padding-top: 20px;

    ${media.tablet} {
        display: none;
    }

    a:first-of-type {
        margin-right: 14px;
    }
`

export const Item = styled.div`
    width: 50%;
    display: flex;
    max-width: 136px;
    align-items: flex-start;
    flex-direction: column;
    margin: 0 18px 22px 0;

    svg {
        width: 20px;
        height: auto;
        margin-left: 6px;
    }

    ${media.desktop} {
        align-items: center;
        text-align: center;
        max-width: initial;
        margin: 0 0 40px 0;

        svg {
            width: 36px;
            margin-left: 10px;
        }
    }
`
