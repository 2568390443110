import { colors, media } from '@variables'

import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import MuiContainer from '@material-ui/core/Container'

export const Subtitle = styled(Typography)``
export const Container = styled(MuiContainer)``

export const Wrap = styled.div`
    width: 100%;
    color: white;
    padding: 60px 0;
    background-color: ${colors.green[500]};

    ${media.desktop} {
        padding: 100px 0;
    }

    ${Subtitle} {
        padding-bottom: 20px;
        color: ${colors.green[100]};

        ${media.desktop} {
            padding-bottom: 60px;
        }
    }

    ${Container} {
        max-width: 1118px !important;
    }
`

export const Row = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    ${media.desktop} {
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
    }
`

export const Col = styled.div`
    width: 100%;

    :first-of-type {
        margin-bottom: 30px;
    }

    ${media.desktop} {
        width: 47%;

        :first-of-type {
            margin-bottom: 0px;
        }
    }
`

export const PlayerWrap = styled.div`
    width: 100%;
    height: 282px;
    overflow: hidden;
    border-radius: 40px;
`

export const Table = styled.table`
    width: 100%;
    margin: 14px 0;
    border: 0px solid;
    border-radius: 11px;
    border-collapse: collapse;
    background-color: ${colors.green[400]};

    padding: 12px;

    tr {
        font-weight: 500;

        :nth-child(2) {
            color: ${colors.surface[900]};
            background-color: ${colors.green[50]};
        }
        :nth-child(3) {
            color: ${colors.green[50]};
        }
    }
    th {
        font-size: 12px;
        border: 0px solid;
        text-align: left;
        line-height: normal;
        letter-spacing: 0.41px;
        text-transform: uppercase;

        padding: 12px 12px 0 0;

        ${media.phone} {
            padding: 12px 24px 0 0;
        }
    }
    td {
        font-size: 14px;
        border: 0px solid;
        line-height: normal;
        padding: 10px 12px 10px 0;

        ${media.phone} {
            padding: 10px 24px 10px 0;
        }

        :first-of-type {
            padding: 0;
            text-align: center;

            width: 80px;

            ${media.phone} {
                width: 100px;
            }
        }
        :last-of-type {
            padding-right: 0;
        }
    }
`
