import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import MuiContainer from '@material-ui/core/Container'

import { colors, media } from '@variables'

export const Text = styled(Typography)``
export const Title = styled(Typography)``
export const Container = styled(MuiContainer)``

export const Wrap = styled.div`
    width: 100%;
    color: white;
    padding: 120px 0 116px 0;
    background-color: ${colors.blue[500]};

    ${media.xl} {
        padding: 148px 0 180px 0;
    }
`

export const Row = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    ${media.xl} {
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
    }
`

const Col = styled.div`
    width: 100%;
    display: flex;
    position: relative;
    z-index: 1;
    flex-direction: column;

    ${media.xl} {
        width: 50%;
    }
`

export const Left = styled(Col)`
    text-align: center;
    align-items: center;
    padding-bottom: 30px;

    ${media.xl} {
        text-align: left;
        padding-bottom: 0;
        align-items: flex-start;
    }

    h6 {
        padding-bottom: 10px;
        color: ${colors.blue[300]};
    }

    button {
        box-shadow: none;
    }

    ${Title} {
        max-width: 320px;
    }
    ${Text} {
        max-width: 440px;
        padding: 24px 0 30px 0;

        ${media.xl} {
            padding: 24px 0 60px 0;
        }
    }
`

export const Right = styled(Col)``

export const Items = styled.div`
    display: flex;
    padding-bottom: 34px;
    flex-direction: column;

    ${media.tablet} {
        flex-direction: row;
    }
    ${media.xl} {
        padding-bottom: 46px;
    }

    div {
        :not(:last-of-type) {
            padding: 0 0 16px 0;

            ${media.tablet} {
                padding: 0 38px 0 0;
            }
        }
    }
`

export const Item = styled.div`
    display: flex;
    padding: 0;
    text-align: left;

    img {
        margin-right: 16px;
    }

    h5 {
        line-height: normal;
    }
    p {
        font-size: 16px;
    }
`

export const ImgWrap = styled.div`
    position: relative;

    img {
        width: 100%;
        height: auto;
        margin: 0 auto;
        display: block;
        max-width: 440px;

        ${media.xl} {
            margin: initial;
            max-width: initial;
        }
    }
`

export const Img = styled.img`
    width: 100%;
    height: auto;
    display: block;
    max-height: 922px;
    object-fit: cover;
`

export const Satellite = styled.div`
    position: absolute;
    display: flex;
    border-radius: 50%;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    color: ${colors.blue[400]};

    left: 0;
    right: 0;
    width: 126px;
    height: 126px;
    bottom: -56px;
    margin: 0 auto;

    ${media.xl} {
        margin: initial;
        left: 130px;
        bottom: 10px;
        right: initial;
    }

    h5 {
        font-size: 36px;
        line-height: normal;

        ${media.tablet} {
            font-size: 40px;
        }
    }
    p {
        font-size: 14px;
        line-height: normal;

        ${media.tablet} {
            font-size: 16px;
        }
    }
`
