import { colors, media } from '@variables'

import styled from 'styled-components'
import MuiContainer from '@material-ui/core/Container'

export const Container = styled(MuiContainer)``

export const Wrap = styled.div`
    width: 100%;
    color: ${colors.primary.white};
    background-color: ${colors.blue[500]};

    padding: 80px 0 100px 0;
    ${media.tablet} {
        padding: 160px 0 200px 0;
    }

    ${Container} {
        max-width: 1168px !important;
    }
`

export const Row = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    ${media.tablet} {
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
    }
`

export const Col = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;

    :first-of-type {
        margin-bottom: 30px;
    }

    ${media.tablet} {
        width: 47%;

        :first-of-type {
            max-width: 445px;
            margin-bottom: 0px;
        }
    }

    img {
        width: 100%;
        height: auto;
    }

    h3 {
        padding-bottom: 12px;
    }
    h6 {
        padding-bottom: 4px;
        color: ${colors.blue[300]};
    }
`
