import styled from 'styled-components'

import { media, colors } from '@variables'
import Bkg from '@static/images/heart-bg.webp'
import Typography from '@material-ui/core/Typography'

export const Text = styled(Typography)``

export const Wrap = styled.div`
    width: 100%;
    color: white;
    overflow: hidden;
    padding: 80px 0;
    background-color: ${colors.blue[500]};

    background-size: 394px auto;
    background-position: calc(100% + 120px) -80px;
    background-repeat: no-repeat;
    background-image: url(${Bkg});

    ${media.desktop} {
        padding: 180px 0;
        background-image: none;
    }
`

export const Row = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    ${media.desktop} {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
`

const Col = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;

    ${media.desktop} {
        width: 50%;
    }
`

export const Left = styled(Col)`
    text-align: center;
    align-items: center;

    ${media.desktop} {
        max-width: 590px;
        text-align: left;
        align-items: flex-start;
    }

    h2 {
        max-width: 520px;
    }
    ${Text} {
        max-width: 490px;
        padding: 20px 0 30px 0;

        ${media.desktop} {
            padding: 30px 0 50px 0;
        }
    }
    h6 {
        color: ${colors.blue[300]};
        padding-bottom: 10px;
    }
`

export const Right = styled(Col)``

export const ButtonWrap = styled.div`
    display: block;
    padding-top: 24px;

    button {
        color: white;
        height: 60px;
        min-width: 235px;
        background-color: #004382;
        box-shadow: 0px 15px 30px rgba(0, 67, 130, 0.4);
    }

    ${media.desktop} {
        display: none;
    }
`

export const ImgWrap = styled.div`
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;

    padding: 60px 0 0 0;

    ${media.desktop} {
        justify-content: flex-end;
    }

    :after {
        content: ' ';
        width: 776px;
        left: 0;
        right: 0;
        margin: auto;
        height: 610px;
        position: absolute;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url(${Bkg});

        display: none;

        ${media.desktop} {
            display: block;
        }
    }

    img {
        width: 100%;
        height: auto;
        display: block;
        max-width: 240px;

        ${media.desktop} {
            max-width: 325px;
        }
    }
`

export const Items = styled.div`
    display: flex;
    flex-wrap: wrap;
    max-width: 410px;
    justify-content: center;

    ${media.desktop} {
        max-width: initial;
        flex-wrap: nowrap;
        justify-content: flex-start;
    }
`

export const Item = styled.div`
    display: flex;
    padding: 8px;
    text-align: center;
    border-radius: 20px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #1970bf;

    margin: 10px;
    width: 120px;
    height: 120px;
    min-width: 120px;
    min-height: 120px;

    ${media.tablet} {
        width: 140px;
        height: 140px;
        min-width: 140px;
        min-height: 140px;
    }

    ${media.desktop} {
        margin: 0 10px 0 0;
    }

    p {
        line-height: normal;
        padding-top: 20px;
        font-size: 14px;

        ${media.tablet} {
            font-size: 16px;
        }
    }
`
