import React from 'react'

function Icon(props: TODO_ANY) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="56"
            height="56"
            fill="none"
            viewBox="0 0 56 56"
            {...props}
        >
            <circle cx="28" cy="28.014" r="27.5" fill="#F9FCFB"></circle>
            <path
                fill="#0060B8"
                fillRule="evenodd"
                d="M15.556 28.014a1.1 1.1 0 011.1-1.1h22.687a1.1 1.1 0 010 2.2H16.656a1.1 1.1 0 01-1.1-1.1z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#0060B8"
                fillRule="evenodd"
                d="M15.878 28.792a1.1 1.1 0 010-1.556L26.465 16.65a1.1 1.1 0 011.556 1.555l-9.81 9.81 9.81 9.81a1.1 1.1 0 11-1.556 1.555L15.878 28.792z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}

export default Icon
