/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react'
import { Fade } from 'react-awesome-reveal'
import { useTranslation } from 'react-i18next'
import Typography from '@material-ui/core/Typography'

import { Routes } from '@variables'
import TeamDesktopImg from '@static/images/team.webp'
import TeamMobileImg from '@static/images/team-mobile.webp'
import { Overflow } from 'client/components/shared/styled'
import { Wrap, Container, Col, Button, TextWrap, Right, Row } from './styled'

const Team = () => {
    const [t] = useTranslation()

    return (
        <Wrap id={Routes.TEAM_HASH}>
            <Container>
                <Row>
                    <Col>
                        <Overflow>
                            <Fade triggerOnce direction="up" duration={1500}>
                                <Typography variant="h6">
                                    {t('team-undertitle')}
                                </Typography>
                            </Fade>
                        </Overflow>

                        <Overflow>
                            <Fade
                                triggerOnce
                                direction="up"
                                delay={100}
                                duration={1500}
                            >
                                <Typography variant="h3">
                                    {t('team-title')}
                                </Typography>
                            </Fade>
                        </Overflow>
                        <TextWrap>
                            <Fade triggerOnce delay={200} duration={2500}>
                                <Typography>{t('team-text1')}</Typography>
                            </Fade>
                            <Fade triggerOnce delay={300} duration={2500}>
                                <Typography>{t('team-text2')}</Typography>
                            </Fade>
                            <Fade triggerOnce delay={400} duration={2500}>
                                <Typography>{t('team-text3')}</Typography>
                            </Fade>
                        </TextWrap>
                        <Fade duration={2500} triggerOnce delay={500}>
                            <Button
                                size="large"
                                color="inherit"
                                // @ts-ignore
                                target="_blank"
                                variant="contained"
                                href={Routes.ABOUT_TEAM}
                                rel="noreferrer noopener"
                            >
                                {t('about-team')}
                            </Button>
                        </Fade>
                    </Col>
                    <Right>
                        <img
                            width="526"
                            height="945"
                            alt="VOS team"
                            loading="lazy"
                            src={TeamDesktopImg}
                        />
                        <img
                            src={TeamMobileImg}
                            alt="VOS team"
                            width="750"
                            height="450"
                        />
                    </Right>
                </Row>
            </Container>
        </Wrap>
    )
}

export default Team
