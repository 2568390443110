import Typography from '@material-ui/core/Typography'
import React from 'react'
import { Fade } from 'react-awesome-reveal'
import { useTranslation } from 'react-i18next'

import Counter from 'client/components/Counter'
import AppleStoreButton from 'client/components/shared/AppleStoreButton'
import GooglePlayButton from 'client/components/shared/GooglePlayButton'
import { Overflow } from 'client/components/shared/styled'
import Star from 'client/components/SVG/Star'
import {
    Container,
    Desktop,
    Inner,
    Item,
    Left,
    Mobile,
    Right,
    Row,
    Wrap
} from './styled'

const Download = () => {
    const [t] = useTranslation()

    return (
        <Wrap>
            <Container>
                <Inner>
                    <Row>
                        <Left>
                            <Overflow>
                                <Fade
                                    triggerOnce
                                    direction="up"
                                    duration={1500}
                                >
                                    <Typography variant="h3">
                                        {t('download-title1')}
                                    </Typography>
                                </Fade>
                            </Overflow>
                            <Overflow>
                                <Fade
                                    triggerOnce
                                    direction="up"
                                    delay={100}
                                    duration={1500}
                                >
                                    <Typography variant="h3">
                                        {t('download-title2')}
                                    </Typography>
                                </Fade>
                            </Overflow>

                            <Fade triggerOnce delay={200} duration={2500}>
                                <Typography variant="h5">
                                    {t('download-text')}
                                </Typography>
                            </Fade>

                            <Fade triggerOnce delay={300} duration={2500}>
                                <Desktop>
                                    <AppleStoreButton size="medium" />
                                    <GooglePlayButton size="medium" />
                                </Desktop>
                            </Fade>
                        </Left>
                        <Right>
                            <Item>
                                <Typography variant="h3">
                                    <Counter
                                        head={12}
                                        duration={2}
                                        tail={1200}
                                    />
                                    k+
                                </Typography>
                                <Typography variant="h6">
                                    {t('download-item1')}
                                </Typography>
                            </Item>

                            <Item>
                                <Typography variant="h3">
                                    <Counter duration={2} head={0.7} tail={4} />
                                    ,9
                                    <Star />
                                </Typography>
                                <Typography variant="h6">
                                    {t('download-item2')}
                                </Typography>
                            </Item>

                            <Item>
                                <Typography variant="h3">
                                    <Counter head={0} duration={2} tail={8} />+
                                </Typography>
                                <Typography variant="h6">
                                    {t('download-item3')}
                                </Typography>
                            </Item>

                            <Item>
                                <Typography variant="h3">
                                    <Counter head={4} duration={2} tail={33} />%
                                </Typography>
                                <Typography variant="h6">
                                    {t('download-item4')}
                                </Typography>
                            </Item>
                        </Right>
                        <Mobile>
                            <AppleStoreButton size="medium" />
                            <GooglePlayButton size="medium" />
                        </Mobile>
                    </Row>
                </Inner>
            </Container>
        </Wrap>
    )
}

export default Download
