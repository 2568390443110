import styled from 'styled-components'
import MuiContainer from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

import Bg from '@static/images/faq-bg.webp'
import { colors, media, transition } from '@variables'

export const Container = styled(MuiContainer)``
export const Title = styled(Typography)``

export const Wrap = styled.div`
    width: 100%;
    background-color: white;

    background-image: url(${Bg});
    background-repeat: no-repeat;

    padding: 80px 0 340px 0;
    background-size: 200% auto;
    background-position: center calc(100% + 100px);

    ${media.phone} {
        background-size: 160% auto;
        background-position: center calc(100% + 240px);
    }

    ${media.tablet} {
        padding: 140px 0 340px 0;
        background-size: 1400px auto;
        background-position: calc(100% + 280px) calc(100% + 280px);
    }
    ${media.custom(1400)} {
        background-size: 2000px auto;
        background-position: calc(100% + 560px) calc(100% + 400px);
    }
    ${media.custom(2000)} {
        background-size: 2500px auto;
        background-position: calc(100% + 760px) calc(100% + 460px);
    }
    ${media.custom(4000)} {
        background-position: right calc(100% + 460px);
    }

    ${Title} {
        max-width: 380px;
    }
`

export const Col = styled.div`
    width: 100%;
    display: flex;
    max-width: 660px;
    flex-direction: column;
`

export const Buttons = styled.div`
    padding: 24px 0 0 0;
    display: flex;
    flex-direction: column;

    ${media.phone} {
        flex-direction: row;
    }

    a,
    button {
        box-shadow: none;

        :last-of-type {
            svg {
                margin-right: 20px;
            }
        }
    }
`

export const Divider = styled.div`
    width: 200px;
    height: 1px;
    opacity: 0.6;
    background: #c4c4c4;
    margin: 40px 0;

    ${media.phone} {
        margin: 70px 0 50px 0;
    }
`

export const Tabs = styled.div`
    overflow: hidden;
    padding-top: 30px;
`

export const TabContent = styled.div`
    max-height: 0;
`

export const TabLabel = styled.label`
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: flex-start;

    p {
        padding: 0 0 0 20px;
    }

    /* Icon */
    :hover {
        transition: ${transition.main};
    }
    :before {
        content: '+';
        color: black;
        width: 40px;
        height: 40px;
        min-width: 40px;
        min-height: 40px;
        max-width: 40px;
        font-size: 24px;
        max-height: 40px;
        border-radius: 50%;
        background-color: #ededed;
        color: ${colors.primary.black};
        display: flex;
        align-items: center;
        justify-content: center;
    }
`

export const Tab = styled.div`
    width: 100%;
    overflow: hidden;

    :not(:last-of-type) {
        margin: 0 0 10px 0;
    }

    input {
        opacity: 0;
        z-index: -1;
        position: absolute;
    }

    input:checked {
        + ${TabLabel} {
            :before {
                content: '−';
            }
        }
        ~ ${TabContent} {
            max-height: 100vh;
            padding: 14px;

            ${media.tablet} {
                padding: 14px 48px 14px 48px;
            }
        }
    }
`
