import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import MuiContainer from '@material-ui/core/Container'

import { colors, media } from '@variables'
import Bg1 from '@static/images/benefits-item1.webp'
import Bg2 from '@static/images/benefits-item2.webp'
import { AccessibleButton } from 'client/components/shared/styled'

export const Text = styled(Typography)``
export const Container = styled(MuiContainer)``

export const Slide = styled.div`
    width: 100%;
    background-repeat: no-repeat;
    background-position: bottom right;
    background-color: ${colors.primary.white};

    height: 560px;
    padding: 30px;
    border-radius: 20px;
    background-size: 240px auto;

    ${media.desktop} {
        height: 677px;
        padding: 53px;
        border-radius: 60px;
        background-size: 500px auto;
    }

    h4 {
        max-width: 320px;
    }

    ${Text} {
        font-size: 16px;
        max-width: 285px;
        line-height: 24px;
        padding: 22px 0 32px 0;
    }
`

export const Bottom = styled.div`
    width: 100%;
    color: white;
    display: flex;
    padding-top: 60px;
    align-items: center;
    flex-direction: column;

    ${media.tablet} {
        padding-top: 120px;
    }

    a {
        margin-top: 14px;
    }
`

export const Wrap = styled.div`
    width: 100%;
    position: relative;
    background-color: ${colors.blue[500]};

    padding: 0 0 60px 0;
    ${media.tablet} {
        padding: 0 0 120px 0;
    }

    ${Container} {
        z-index: 1;
        color: white;
        position: relative;
    }

    .swiper-wrapper {
        width: 100%;

        ${media.desktop} {
            margin-left: 16px;
        }
        ${media.xl} {
            margin-left: -20%;
        }
        ${media.custom(1300)} {
            margin-left: -300px;
        }
    }
    .swiper-slide {
        width: 100%;
        box-sizing: border-box;

        max-width: 300px;

        ${media.custom(340)} {
            max-width: 320px;
        }
        ${media.desktop} {
            max-width: 600px;
        }

        :nth-child(1) {
            ${Slide} {
                background-size: 140px auto;

                ${media.desktop} {
                    background-size: 250px auto;
                }

                background-image: url(${Bg1});
            }
        }
        :nth-child(2) {
            ${Slide} {
                background-image: url(${Bg2});
            }
        }

        margin: 0 0 0 10px;

        ${media.desktop} {
            :not(:last-of-type) {
                margin: 0 60px 0 0;
            }
        }
    }
`

export const PaginationWrap = styled.div`
    z-index: 1;
    display: flex;
    padding-top: 30px;
    position: relative;
    justify-content: center;

    ${media.desktop} {
        display: none;
    }

    .swiper-pagination-bullet {
        height: 4px;
        width: 20px;
        opacity: 0.3;
        margin: 0 2.5px;
        border-radius: 2px;
        background-color: white;
    }
    .swiper-pagination-bullet-active {
        width: 40px;
        opacity: 1;
    }
`

export const Ul = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
    max-width: 320px;
`

export const Li = styled.li`
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: ${colors.primary.green1};

    svg {
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
        margin-right: 16px;
        align-self: flex-start;
    }

    :not(:last-of-type) {
        padding-bottom: 14px;
    }
`

export const Head = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-bottom: 40px;

    ${media.xl} {
        padding-bottom: 55px;
    }
`

export const Nav = styled.div`
    width: 125px;
    justify-content: space-between;

    display: none;

    ${media.xl} {
        display: flex;
    }
`

export const NavButton = styled(AccessibleButton)`
    border: 0;
    outline: 0;
    width: 55px;
    height: 55px;
    background-color: transparent;

    :disabled {
        opacity: 0.6;
    }
`
