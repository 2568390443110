import React from 'react'
import { Fade } from 'react-awesome-reveal'
import Typography from '@material-ui/core/Typography'
import CountdownComponent, { CountdownRenderProps } from 'react-countdown'

import { Inner, Wrap, Col } from './styled'

const renderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
    variant = 'complex'
}: CountdownRenderProps & Pick<Props, 'variant'>) => {
    if (!completed && variant === 'simple') {
        return (
            <span>
                {days}D {hours}H {minutes}min {seconds}s
            </span>
        )
    }

    if (!completed && variant === 'complex') {
        return (
            <Inner>
                <Col>
                    <Fade
                        triggerOnce
                        duration={800}
                        cascade
                        delay={100}
                        direction="up"
                    >
                        <Typography variant="h6">{days}</Typography>
                        <Typography>Days</Typography>
                    </Fade>
                </Col>
                <Col>
                    <Fade
                        triggerOnce
                        duration={800}
                        cascade
                        delay={200}
                        direction="up"
                    >
                        <Typography variant="h6">{hours}</Typography>
                        <Typography>Hours</Typography>
                    </Fade>
                </Col>
                <Col>
                    <Fade
                        triggerOnce
                        duration={800}
                        cascade
                        delay={300}
                        direction="up"
                    >
                        <Typography variant="h6">{minutes}</Typography>
                        <Typography>Minutes</Typography>
                    </Fade>
                </Col>
                <Col>
                    <Fade
                        triggerOnce
                        duration={800}
                        cascade
                        delay={400}
                        direction="up"
                    >
                        <Typography variant="h6">{seconds}</Typography>
                        <Typography>Seconds</Typography>
                    </Fade>
                </Col>
            </Inner>
        )
    }

    return <></>
}

type Props = {
    date: Date
    variant?: 'simple' | 'complex'
}

const Countdown = ({ date, variant = 'complex' }: Props) => (
    <Wrap>
        <CountdownComponent
            date={date}
            renderer={(props) => renderer({ ...props, variant })}
        />
    </Wrap>
)

export default Countdown
