import React from 'react'

function Icon(props: TODO_ANY) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="9"
            height="8"
            fill="none"
            viewBox="0 0 9 8"
            {...props}
        >
            <path
                fill="#A3D3FF"
                fillRule="evenodd"
                d="M4.578 8a.719.719 0 01-.51-.21.704.704 0 01.002-1.004L6.905 4 4.07 1.215A.705.705 0 014.068.209.726.726 0 015.086.207l3.347 3.29a.707.707 0 010 1.007L5.086 7.792A.722.722 0 014.578 8"
                clipRule="evenodd"
            ></path>
            <path
                fill="#A3D3FF"
                fillRule="evenodd"
                d="M4.711 0c.184 0 .37.07.51.21.28.278.28.727-.002 1.004L2.384 4 5.22 6.785a.705.705 0 01.002 1.006.726.726 0 01-1.018.002L.856 4.503a.707.707 0 010-1.007L4.203.208A.722.722 0 014.711 0"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}

export default Icon
