/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react'
import { Fade } from 'react-awesome-reveal'
import { useTranslation } from 'react-i18next'
import Typography from '@material-ui/core/Typography'

import { Routes } from '@variables'
import Forbes from '@static/images/forbes.webp'
import Dailyplanet from '@static/images/dailyplanet.webp'
import Czechcrunch from '@static/images/czechcrunch.webp'
import { Overflow } from 'client/components/shared/styled'
import Discrod from 'client/components/SVG/Social/discord'
import { Wrap, Container, Row, Col, Banner, Logos, Button } from './styled'

const About = () => {
    const [t] = useTranslation()

    return (
        <Wrap id={Routes.ABOUT_HASH}>
            <Container>
                <Row>
                    <Col>
                        <Overflow>
                            <Fade triggerOnce direction="up" duration={1500}>
                                <Typography variant="h4">
                                    {t('about-title')}
                                </Typography>
                            </Fade>
                        </Overflow>
                        <Fade triggerOnce delay={100} duration={2500}>
                            <Typography>{t('about-text')}</Typography>
                        </Fade>
                        <Typography variant="h6">
                            {t('vos-in-media')}
                        </Typography>
                        <Logos>
                            <a
                                href={Routes.FORBES}
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                <img
                                    src={Forbes}
                                    alt="forbes"
                                    loading="lazy"
                                    width="125"
                                    height="31"
                                />
                            </a>
                            <a
                                href={Routes.DAILY}
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                <img
                                    src={Dailyplanet}
                                    alt="Daily planet"
                                    loading="lazy"
                                    width="276"
                                    height="52"
                                />
                            </a>
                            <a
                                href={Routes.CZECHCRUNCH}
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                <img
                                    src={Czechcrunch}
                                    alt="Czechcrunch"
                                    loading="lazy"
                                    width="235"
                                    height="29"
                                />
                            </a>
                        </Logos>
                    </Col>
                </Row>
                <Banner>
                    <Typography variant="h3">
                        {t('join-our-discord-community')}
                    </Typography>
                    <Button
                        size="large"
                        color="inherit"
                        // @ts-ignore
                        target="_blank"
                        variant="contained"
                        href={Routes.DISCORD}
                        rel="noreferrer noopener"
                    >
                        <Discrod />
                        {t('join-discord-now')}
                    </Button>
                </Banner>
            </Container>
        </Wrap>
    )
}

export default About
