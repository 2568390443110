/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useContext } from 'react'
import { Fade } from 'react-awesome-reveal'
import { useTranslation } from 'react-i18next'
import Typography from '@material-ui/core/Typography'

import { now, Routes } from '@variables'
import { Web3Context } from 'client/context/Web3'
import Countdown from 'client/components/Countdown'
import MobileBkg from '@static/images/hero-mobile.webp'
import { Overflow } from 'client/components/shared/styled'
import {
    Wrap,
    Text,
    Inner,
    Button,
    TextWrap,
    Container,
    CountdownText,
    MobileImgWrap,
    CountdownWrap
} from './styled'

const Hero = () => {
    const [t] = useTranslation()
    const { saleDate, hahdleMintButton } = useContext(Web3Context)

    return (
        <Wrap>
            <Container>
                <Inner>
                    <TextWrap>
                        <Overflow>
                            <Fade direction="up" duration={1500} triggerOnce>
                                <Typography variant="h3">
                                    {t('hero-title-1')}
                                </Typography>
                            </Fade>
                        </Overflow>
                        <Overflow>
                            <Fade
                                direction="up"
                                duration={1500}
                                delay={200}
                                triggerOnce
                            >
                                <Typography variant="h3">
                                    {t('hero-title-2')}
                                </Typography>
                            </Fade>
                        </Overflow>

                        <Fade duration={2500} triggerOnce delay={300}>
                            <Text className="green-500">{t('hero-text')}</Text>
                        </Fade>

                        {now < saleDate ? (
                            <>
                                <CountdownWrap>
                                    <CountdownText variant="h6">
                                        <span
                                            className="green-500"
                                            style={{ fontWeight: 700 }}
                                        >
                                            {t('hero-drop2')}
                                        </span>
                                        <Countdown
                                            variant="simple"
                                            date={saleDate}
                                        />
                                    </CountdownText>
                                </CountdownWrap>

                                <Fade duration={2500} triggerOnce delay={450}>
                                    <Button
                                        size="medium"
                                        color="inherit"
                                        // @ts-ignore
                                        target="_blank"
                                        variant="contained"
                                        href={Routes.DISCORD}
                                        rel="noreferrer noopener"
                                    >
                                        <span>{t('join-whitelist')}</span>
                                    </Button>
                                </Fade>
                            </>
                        ) : (
                            <Fade duration={2500} triggerOnce delay={450}>
                                <Button
                                    size="medium"
                                    color="inherit"
                                    variant="contained"
                                    onClick={async () => {
                                        await hahdleMintButton()
                                    }}
                                >
                                    <span>{t('mint-now')}</span>
                                </Button>
                            </Fade>
                        )}
                    </TextWrap>
                    <MobileImgWrap>
                        <img
                            width="787"
                            height="520"
                            alt="Vos NFT"
                            loading="lazy"
                            src={MobileBkg}
                        />
                    </MobileImgWrap>
                </Inner>
            </Container>
        </Wrap>
    )
}

export default Hero
