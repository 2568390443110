import styled from 'styled-components'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import MuiContainer from '@material-ui/core/Container'

import { colors, media } from '@variables'
import Bkg from '@static/images/hero.webp'
import BkgMobile from '@static/images/hero-mobile.webp'

export const Container = styled(MuiContainer)``
export const CountdownText = styled(Typography)``
export const Text = styled(Typography)``
export const Button = styled(MuiButton)``

export const Wrap = styled.div`
    width: 100%;
    position: relative;
    background-color: white;

    ${media.tablet} {
        padding: 160px 0 100px 0;
    }

    ${Container} {
        max-width: 1288px !important;

        @media (max-width: 853px) {
            padding: 0;
        }
    }

    ${Button} {
        padding: 0;
        height: 50px;
        padding: 0 20px;
        font-size: 16px;
        line-height: normal;
        min-width: 180px;

        svg {
            margin-right: 10px;
        }

        svg,
        path {
            fill: white;
            color: white;
        }
    }
`

export const Inner = styled.div`
    width: 100%;
    display: flex;
    overflow: hidden;
    background-color: #f3faf7;
    background-repeat: no-repeat;

    border-radius: 0;
    text-align: center;
    align-items: center;
    flex-direction: column;
    padding: 160px 0 100px 0;

    ${media.tablet} {
        height: 530px;
        text-align: left;
        padding: 0 0 0 76px;
        flex-direction: row;
        border-radius: 60px;
        background-size: 40% auto;
        background-position: 92% 50%;
        background-image: url(${BkgMobile});
    }
    ${media.desktop} {
        background-position: calc(100% - 76px) 50%;
    }
    ${media.xl} {
        background-position: right;
        background-size: auto 100%;
        background-image: url(${Bkg});
    }
`

export const TextWrap = styled.div`
    ${Text} {
        max-width: 285px;
        padding: 10px 0 26px 0;
    }

    @media (max-width: 853px) {
        display: flex;
        align-items: center;
        flex-direction: column;
    }
`

export const MobileImgWrap = styled.div`
    display: block;
    padding-top: 50px;

    img {
        width: 100%;
        height: auto;
        display: block;
    }

    ${media.tablet} {
        display: none;
    }
`

export const CountdownWrap = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 6px 0 16px 0;

    ${media.desktop} {
        align-items: flex-start;
    }

    ${CountdownText} {
        display: flex;
        font-size: 17px;
        font-weight: 400;
        line-height: normal;
        color: ${colors.surface[800]};

        div {
            width: inherit;
            display: block;
            margin: 0 0 0 6px;
        }
        span {
            display: block;
        }
    }
`
