import { useMedia } from 'react-use'
import { Fade } from 'react-awesome-reveal'
import { useTranslation } from 'react-i18next'
import React, { useState, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'

import Checkmark from 'client/components/SVG/Checkmark'
import { Overflow } from 'client/components/shared/styled'
import {
    Wrap,
    Title,
    Container,
    Stepper,
    Step,
    StepperInner,
    Item,
    Span,
    Button,
    Ul,
    Li
} from './styled'
import { Routes } from '@variables'

const Roadmap = () => {
    const [t] = useTranslation()
    const isWide = useMedia('(min-width: 480px)')

    const [visible, setVisible] = useState(true)

    useEffect(() => {
        setVisible(isWide)
    }, [isWide])

    return (
        <Wrap>
            <Overflow id={Routes.ROADMAP_HASH}>
                <Fade triggerOnce direction="up" duration={1500}>
                    <Title variant="h3" align="center">
                        {t('roadmap-title')}
                    </Title>
                </Fade>
            </Overflow>

            <Container>
                {Array.from({ length: visible ? 9 : 3 })
                    .fill(0)
                    .map((i, idx) => (
                        <Item key={`${idx}`}>
                            <Overflow>
                                <Fade
                                    triggerOnce
                                    direction="up"
                                    duration={1500}
                                >
                                    <Typography variant="h5">
                                        {t(`roadmap-item${idx + 1}-title`)}
                                    </Typography>
                                </Fade>
                            </Overflow>
                            <Ul>
                                <Fade triggerOnce duration={2500} delay={150}>
                                    <Li>
                                        <Checkmark />
                                        <Span>
                                            {t(`roadmap-item${idx + 1}-text1`)}
                                        </Span>
                                    </Li>
                                </Fade>
                                <Fade triggerOnce duration={2500} delay={300}>
                                    <Li>
                                        <Checkmark />
                                        <Span>
                                            {t(`roadmap-item${idx + 1}-text2`)}
                                        </Span>
                                    </Li>
                                </Fade>
                            </Ul>
                        </Item>
                    ))}

                <Stepper progress={0} visible={visible}>
                    <StepperInner>
                        <Step complete>
                            <span>0%</span>
                        </Step>
                        <Step>
                            <span>10%</span>
                        </Step>
                        <Step>
                            <span>30%</span>
                        </Step>
                        {visible && (
                            <>
                                <Step>
                                    <span>40%</span>
                                </Step>
                                <Step>
                                    <span>50%</span>
                                </Step>
                                <Step>
                                    <span>70%</span>
                                </Step>
                                <Step>
                                    <span>80%</span>
                                </Step>
                                <Step>
                                    <span>90%</span>
                                </Step>
                                <Step>
                                    <span>100%</span>
                                </Step>
                            </>
                        )}
                    </StepperInner>
                </Stepper>
            </Container>

            <Button
                size="large"
                variant="contained"
                onClick={() => setVisible(!visible)}
            >
                {visible ? t('hide-roadmap') : t('show-roadmap')}
            </Button>
        </Wrap>
    )
}

export default Roadmap
