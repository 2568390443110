import styled from 'styled-components'
import MuiButton from '@material-ui/core/Button'
import MuiContainer from '@material-ui/core/Container'

import { colors, media } from '@variables'
import CharImg from '@static/images/about-char.webp'
import RightBg from '@static/images/about-right.webp'
import DiscordBg from '@static/images/about-discord.webp'

export const Button = styled(MuiButton)``
export const Container = styled(MuiContainer)``

export const Wrap = styled.div`
    width: 100%;
    padding: 80px 0;
    color: ${colors.surface[900]};
    background-color: ${colors.surface[50]};

    ${media.xl} {
        padding: 80px 0 120px 0;
    }
`

export const Row = styled.div`
    width: 100%;
    display: flex;
    margin: 0 auto;
    max-width: 1000px;
`

export const Col = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;

    ${media.desktop} {
        width: 50%;
    }

    h4 {
        max-width: 460px;
    }
    p {
        max-width: 590px;
        padding: 30px 0 40px 0;

        ${media.desktop} {
            padding: 30px 0 100px 0;
        }
    }
    h6 {
        color: ${colors.surface[200]};
    }
`

export const Logos = styled.div`
    display: flex;
    flex-direction: column;

    padding: 20px 0 46px 0;

    img {
        @media (max-width: 479px) {
            width: 40%;
            height: auto;
        }
    }

    ${media.tablet} {
        align-items: center;
        flex-direction: row;
    }
    ${media.desktop} {
        padding: 20px 0 80px 0;
    }

    a {
        :not(:last-of-type) {
            margin: 0 0 16px 0;

            ${media.tablet} {
                margin: 0 40px 0 0;
            }
        }
    }
`

export const Banner = styled.div`
    width: 100%;
    z-index: 1;
    display: flex;
    background: white;
    position: relative;

    text-align: center;

    background-repeat: no-repeat;
    background-size: 180px auto;
    background-position: top left;
    background-image: url(${DiscordBg});

    align-items: center;
    flex-direction: column;
    justify-content: center;

    height: 300px;
    padding: 40px 20px;
    border-radius: 20px;

    ${media.phone} {
        padding: 40px;
        border-radius: 40px;
    }
    ${media.tablet} {
        height: 340px;
        text-align: left;
        border-radius: 60px;
        flex-direction: row;
        justify-content: space-around;

        background-size: 279px auto, 213px auto;
        background-position: center left, 96% center;
        background-image: url(${DiscordBg}), url(${RightBg});
    }

    :before {
        z-index: 0;
        content: ' ';
        position: absolute;
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-image: url(${CharImg});

        right: 0px;
        width: 260px;
        height: 470px;
        top: calc(-470px);

        display: none;

        ${media.desktop} {
            display: block;
        }
        ${media.xl} {
            right: 40px;
        }
    }

    h3 {
        max-width: 500px;
    }

    ${Button} {
        padding: 0;
        width: 100%;
        height: 80px;
        max-width: 300px;
        margin-top: 30px;

        ${media.tablet} {
            margin-top: 0px;
        }

        svg,
        path {
            fill: white;
            color: white;
        }
        svg {
            width: 34px;
            height: auto;
            margin-right: 20px;
        }
    }
`
