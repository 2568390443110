import React from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@material-ui/core/Typography'

import Img from '@static/images/metaverse.webp'
import { Wrap, Container, Row, Col } from './styled'

const Metaverse = () => {
    const [t] = useTranslation()

    return (
        <Wrap>
            <Container>
                <Row>
                    <Col>
                        <Typography variant="h6">
                            {t('metaverse-undertitle')}
                        </Typography>
                        <Typography variant="h3">
                            {t('metaverse-title')}
                        </Typography>
                        <Typography>
                            {t('metaverse-text1')}
                            <b>{t('metaverse-text2')}</b>
                        </Typography>
                    </Col>
                    <Col>
                        <img
                            src={Img}
                            width="585"
                            height="285"
                            loading="lazy"
                            alt=""
                        />
                    </Col>
                </Row>
            </Container>
        </Wrap>
    )
}

export default Metaverse
