import React from 'react'

function Icon(props: TODO_ANY) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="34"
            fill="none"
            viewBox="0 0 36 34"
            {...props}
        >
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M20.209 1.354l3.654 7.302a2.456 2.456 0 001.852 1.328l8.176 1.177c2.02.292 2.824 2.74 1.362 4.143l-5.913 5.68a2.406 2.406 0 00-.708 2.151l1.396 8.02c.343 1.985-1.768 3.498-3.574 2.56l-7.308-3.79a2.496 2.496 0 00-2.292 0l-7.308 3.79c-1.806.938-3.917-.575-3.572-2.56l1.394-8.02a2.406 2.406 0 00-.708-2.15L.747 15.304C-.715 13.9.09 11.453 2.11 11.16l8.176-1.177a2.46 2.46 0 001.854-1.328l3.652-7.302c.904-1.805 3.514-1.805 4.418 0z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}

export default Icon
