import React, { useContext } from 'react'
import { Fade } from 'react-awesome-reveal'
import Button from '@material-ui/core/Button'
import { useTranslation } from 'react-i18next'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

import PhoneImg from '@static/images/phone.webp'
import Icon1 from '@static/images/app-icon1.webp'
import Icon2 from '@static/images/app-icon2.webp'
import Icon3 from '@static/images/app-icon3.webp'
import Icon4 from '@static/images/app-icon4.webp'
import { Overflow } from 'client/components/shared/styled'
import {
    Wrap,
    Row,
    Left,
    Right,
    ImgWrap,
    Items,
    Item,
    Text,
    ButtonWrap
} from './styled'
import { Routes } from '@variables'
import { Web3Context } from 'client/context/Web3'

const App = () => {
    const [t] = useTranslation()
    const { hahdleMintButton } = useContext(Web3Context)

    return (
        <Wrap>
            <Container>
                <Row id={Routes.APP_HASH}>
                    <Left>
                        <Overflow>
                            <Fade direction="up" duration={1500} triggerOnce>
                                <Typography variant="h6">
                                    {t('app-undertitle')}
                                </Typography>
                            </Fade>
                        </Overflow>
                        <Overflow>
                            <Fade
                                direction="up"
                                duration={1500}
                                triggerOnce
                                delay={100}
                            >
                                <Typography variant="h3">
                                    {t('app-title')}
                                </Typography>
                            </Fade>
                        </Overflow>

                        <Fade duration={2500} triggerOnce delay={200}>
                            <Text>{t('app-text')}</Text>
                        </Fade>
                        <Items>
                            <Fade delay={250} duration={2500} triggerOnce>
                                <Item>
                                    <img
                                        src={Icon1}
                                        width="48"
                                        height="48"
                                        loading="lazy"
                                        alt={t('app-item1')}
                                    />
                                    <Typography>{t('app-item1')}</Typography>
                                </Item>
                            </Fade>
                            <Fade delay={300} duration={2500} triggerOnce>
                                <Item>
                                    <img
                                        src={Icon2}
                                        width="48"
                                        height="48"
                                        loading="lazy"
                                        alt={t('app-item2')}
                                    />
                                    <Typography>{t('app-item2')}</Typography>
                                </Item>
                            </Fade>
                            <Fade delay={350} duration={2500} triggerOnce>
                                <Item>
                                    <img
                                        src={Icon3}
                                        width="48"
                                        height="48"
                                        loading="lazy"
                                        alt={t('app-item3')}
                                    />
                                    <Typography>{t('app-item3')}</Typography>
                                </Item>
                            </Fade>
                            <Fade delay={400} duration={2500} triggerOnce>
                                <Item>
                                    <img
                                        src={Icon4}
                                        width="48"
                                        height="48"
                                        loading="lazy"
                                        alt={t('app-item4')}
                                    />
                                    <Typography>{t('app-item4')}</Typography>
                                </Item>
                            </Fade>
                        </Items>
                        <ButtonWrap>
                            <Button
                                size="large"
                                color="secondary"
                                variant="contained"
                                onClick={async () => {
                                    await hahdleMintButton()
                                }}
                            >
                                {t('mint-now')}
                            </Button>
                        </ButtonWrap>
                    </Left>
                    <Right>
                        <ImgWrap>
                            <img
                                src={PhoneImg}
                                loading="lazy"
                                alt="VOS app"
                                width="384"
                                height="707"
                            />
                        </ImgWrap>
                    </Right>
                </Row>
            </Container>
        </Wrap>
    )
}

export default App
