import React from 'react'
import { useMedia } from 'react-use'
import { Fade } from 'react-awesome-reveal'
import Button from '@material-ui/core/Button'
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperSlide } from 'swiper/react'
import Typography from '@material-ui/core/Typography'
import SwiperCore, { Navigation, Pagination } from 'swiper'

import { Routes } from '@variables'
import Prev from 'client/components/SVG/Carousel/Prev'
import Next from 'client/components/SVG/Carousel/Next'
import { Overflow } from 'client/components/shared/styled'
import Checkmark from 'client/components/SVG/GreenCheckmark'
import {
    Ul,
    Li,
    Nav,
    Head,
    Wrap,
    Text,
    Slide,
    Bottom,
    Container,
    NavButton,
    PaginationWrap
} from './styled'

SwiperCore.use([Navigation, Pagination])

export type Props = {
    className?: string
}

const Benefits = ({ className = '' }: Props) => {
    const [t] = useTranslation()

    const isWide = useMedia('(min-width: 1170px)')

    return (
        <Wrap id={Routes.BENEFITS_HASH} className={className}>
            <Container>
                <Head>
                    <Overflow>
                        <Fade triggerOnce direction="up" duration={1500}>
                            <Typography variant="h2">
                                {t('benefits-title')}
                            </Typography>
                        </Fade>
                    </Overflow>
                    <Nav>
                        <NavButton id="swiper-prev">
                            <Prev />
                        </NavButton>

                        <NavButton id="swiper-next">
                            <Next />
                        </NavButton>
                    </Nav>
                </Head>
            </Container>

            <Swiper
                grabCursor
                slidesPerView="auto"
                centeredSlides={isWide}
                pagination={{
                    el: '#swiper-pagination-container'
                }}
                navigation={{
                    prevEl: '#swiper-prev',
                    nextEl: '#swiper-next'
                }}
            >
                <SwiperSlide>
                    <Slide>
                        <Typography variant="h4">
                            {t('benefits-item1-title')}
                        </Typography>
                        <Text>{t('benefits-item1-text')}</Text>
                        <Ul>
                            <Li>
                                <Checkmark />
                                <span>{t('benefits-item1-listitem1')}</span>
                            </Li>
                            <Li>
                                <Checkmark />
                                <span>{t('benefits-item1-listitem2')}</span>
                            </Li>
                        </Ul>
                    </Slide>
                </SwiperSlide>
                <SwiperSlide>
                    <Slide>
                        <Typography variant="h4">
                            {t('benefits-item2-title')}
                        </Typography>
                        <Text>{t('benefits-item2-text')}</Text>
                        <Ul>
                            <Li>
                                <Checkmark />
                                <span>{t('benefits-item2-listitem1')}</span>
                            </Li>
                            <Li>
                                <Checkmark />
                                <span>{t('benefits-item2-listitem2')}</span>
                            </Li>
                        </Ul>
                    </Slide>
                </SwiperSlide>
                <SwiperSlide>
                    <Slide>
                        <Typography variant="h4">
                            {t('benefits-item3-title')}
                        </Typography>
                        <Text>{t('benefits-item3-text')}</Text>
                        <Ul>
                            <Li>
                                <Checkmark />
                                <span>{t('benefits-item3-listitem1')}</span>
                            </Li>
                            <Li>
                                <Checkmark />
                                <span>{t('benefits-item3-listitem2')}</span>
                            </Li>
                            <Li>
                                <Checkmark />
                                <span>{t('benefits-item3-listitem3')}</span>
                            </Li>
                        </Ul>
                    </Slide>
                </SwiperSlide>
                <SwiperSlide>
                    <Slide>
                        <Typography variant="h4">
                            {t('benefits-item4-title')}
                        </Typography>
                        <Text>{t('benefits-item4-text')}</Text>
                        <Ul>
                            <Li>
                                <Checkmark />
                                <span>{t('benefits-item4-listitem1')}</span>
                            </Li>
                            <Li>
                                <Checkmark />
                                <span>{t('benefits-item4-listitem2')}</span>
                            </Li>
                            <Li>
                                <Checkmark />
                                <span>{t('benefits-item4-listitem3')}</span>
                            </Li>
                        </Ul>
                    </Slide>
                </SwiperSlide>
                <SwiperSlide>
                    <Slide>
                        <Typography variant="h4">
                            {t('benefits-item5-title')}
                        </Typography>
                        <Text>{t('benefits-item5-text')}</Text>
                        <Ul>
                            <Li>
                                <Checkmark />
                                <span>{t('benefits-item5-listitem1')}</span>
                            </Li>
                            <Li>
                                <Checkmark />
                                <span>{t('benefits-item5-listitem2')}</span>
                            </Li>
                            <Li>
                                <Checkmark />
                                <span>{t('benefits-item5-listitem3')}</span>
                            </Li>
                            <Li>
                                <Checkmark />
                                <span>{t('benefits-item5-listitem4')}</span>
                            </Li>
                            <Li>
                                <Checkmark />
                                <span>{t('benefits-item5-listitem5')}</span>
                            </Li>
                        </Ul>
                    </Slide>
                </SwiperSlide>
            </Swiper>

            <PaginationWrap id="swiper-pagination-container"></PaginationWrap>

            <Bottom>
                <Typography align="center" variant="h3">
                    {t('want-nft')}
                </Typography>
                <Button
                    size="medium"
                    target="_blank"
                    color="secondary"
                    variant="contained"
                    href={Routes.FOR_HOLDERS}
                >
                    {t('info-for-holders')}
                </Button>
            </Bottom>
        </Wrap>
    )
}

export default Benefits
