import styled from 'styled-components'
import MuiButton from '@material-ui/core/Button'
import MuiContainer from '@material-ui/core/Container'

import { colors, media } from '@variables'
import MapImg from '@static/images/map.webp'

export const Button = styled(MuiButton)``
export const Container = styled(MuiContainer)``

export const Wrap = styled.div`
    width: 100%;
    color: white;
    position: relative;

    background-color: ${colors.primary.green1};

    background-repeat: no-repeat;
    background-size: 1124px auto;

    background-image: none;
    padding: 60px 0 0 0;
    background-position: center calc(100% + 70px);

    ${media.phone} {
        padding: 60px 0;
    }
    ${media.desktop} {
        padding: 80px 0;
    }
    ${media.xl} {
        padding: 80px 0 180px 0;
        background-image: url(${MapImg});
        background-position: calc(50% - 120px) calc(100%);
    }

    ${Container} {
        position: relative;

        @media (max-width: 479px) {
            padding: 0;
        }
    }
`

export const TextWrap = styled.div`
    max-width: 520px;

    padding: 24px 16px 4px 16px;

    ${media.phone} {
        padding: 24px 0 4px 0;
    }
    ${media.desktop} {
        padding: 30px 0 16px 0;
    }

    p {
        padding-bottom: 24px;

        ${media.desktop} {
            padding-bottom: 34px;
        }
    }
`

export const Col = styled.div`
    z-index: 2;
    display: flex;
    max-width: 524px;
    position: relative;
    align-items: center;
    flex-direction: column;
    margin: 0 auto 60px auto;

    width: 100%;
    text-align: center;

    ${media.desktop} {
        width: 48%;
        margin: initial;
        text-align: left;
        align-items: flex-start;
    }

    h6 {
        padding-bottom: 16px;
        color: ${colors.green[300]};
    }
    h2 {
        max-width: 400px;
    }

    ${Button} {
        background-color: ${colors.green[400]};
    }

    :first-of-type {
        ${media.xl} {
            padding-top: 120px;
        }
    }
`

export const Row = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    ${media.desktop} {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    ${media.xl} {
        align-items: flex-start;
    }
`

export const Right = styled.div`
    display: block;

    width: 100%;

    ${media.desktop} {
        width: 48%;
    }

    img {
        width: 100%;
        height: auto;
        display: block;

        :first-of-type {
            display: none;
            ${media.desktop} {
                display: block;
            }
        }

        :last-of-type {
            ${media.phone} {
                border-radius: 15px;
            }
            ${media.tablet} {
                border-radius: 30px;
            }
            ${media.desktop} {
                display: none;
            }
        }
    }
`
