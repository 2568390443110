import React from 'react'

function Icon(props: TODO_ANY) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="56"
            height="56"
            fill="none"
            viewBox="0 0 56 56"
            {...props}
        >
            <circle
                cx="28"
                cy="28.014"
                r="27.5"
                fill="#F9FCFB"
                transform="rotate(-180 28 28.014)"
            ></circle>
            <path
                fill="#0060B8"
                fillRule="evenodd"
                d="M40.444 28.014a1.1 1.1 0 01-1.1 1.1H16.657a1.1 1.1 0 010-2.2h22.687a1.1 1.1 0 011.1 1.1z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#0060B8"
                fillRule="evenodd"
                d="M40.122 27.236a1.1 1.1 0 010 1.555L29.535 39.38a1.1 1.1 0 11-1.556-1.556l9.81-9.81-9.81-9.81a1.1 1.1 0 111.556-1.555l10.587 10.588z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}

export default Icon
